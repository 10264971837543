import {
    Abstract
} from './Abstract';

export class TMUser extends Abstract {
    constructor(session) {
        super('tm/User', session);
        this.name = null;
        this.id_tm_role = null;
        this.id_tm_group = null;
        this.user_type = 1;
        this.email = null;
    }
}
