import {
    Abstract
} from './Abstract';

export class TMProject extends Abstract {
    constructor(session) {
        super('tm/Project', session);
        this.name = null;
        this.start_date = null;
        this.finish_date = null;
        this.status = 1;
        this.id_tm_user_manager = null;
        this.id_tm_customer = null;
    }
}
